import {Menu} from 'antd';
import PropTypes from 'prop-types';

const RightMenu = ({mode}) => {
  return (
    <div className={'w-44 bg-white'}>
      <Menu className={'bg-black text-white'} />
    </div>
  );
};

RightMenu.propTypes = {
  mode: PropTypes.oneOf(['horizontal', 'vertical', 'inline']).isRequired,
};

export default RightMenu;
