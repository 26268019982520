import {Breadcrumb, Image} from 'antd';
import {motion, useInView} from 'framer-motion';
import {useEffect, useRef} from 'react';

import about from '../../assets/Images/cowHome1.jpg';
import about1 from '../../assets/Images/cowMat1.jpg';
import india from '../../assets/Images/indiaMap.png';
import maharashtra from '../../assets/Images/maharashtraMap.png';
import {FooterScreen} from '../FooterScreen';

const state = [
  {
    id: 1,
    state: 'Maharashtra',
  },
  {
    id: 2,
    state: 'Goa',
  },
  {
    id: 3,
    state: 'Madhya Pradesh',
  },
  {
    id: 4,
    state: 'Karnataka',
  },
];

const city = [
  {
    id: 1,
    state: 'Thane',
  },
  {
    id: 2,
    state: 'Pune',
  },
  {
    id: 3,
    state: 'Satara',
  },
  {
    id: 4,
    state: 'Sangli',
  },
  {
    id: 5,
    state: 'Kolhapur',
  },
  {
    id: 6,
    state: 'Ahmadnagar',
  },
  {
    id: 7,
    state: 'Ratnagiri',
  },
  {
    id: 8,
    state: 'Sindhudurga',
  },
  {
    id: 9,
    state: 'Solapur',
  },
  {
    id: 10,
    state: 'Nandurbar',
  },
];

const DealersScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  return(
    <div>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${about1})` : `url(${about})`,
        height: '60vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: '-1px 0%',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'flex flex-col justify-center items-center text-center pt-44'}>
          <Breadcrumb className={'opacity-90'}
            separator={<span className={'separator text-white'}>/</span>}
            items={[
              {
                title: (
                  <span className={'text-white'}>HOME</span>
                ),
              },
              {
                title: (
                  <span className={'text-white'}>DEALERS</span>
                ),
              }]}
          />
          <h1 className={'text-4xl font-bold md:text-6xl text-white'}>Dealers</h1>
        </div>
      </div>

      <div className={'flex flex-col justify-center items-center lg:p-10'}>
        <div ref={ref} style={{
          transform: isInView ? 'none' : 'translateX(-200px)',
          opacity: isInView ? 1 : 0,
          transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }} className={'p-8 md:p-2 text-xl md:text-4xl font-bold md:mb-10'}>
          <h1>Serving You Across India</h1>
        </div>
        <div className={'flex flex-col lg:flex-row'}>
          <div className={'flex justify-center items-center'}>
            <Image src={india} style={{
              width: window.innerWidth < 768 ? '46vh' : '60vh',
              height: window.innerWidth < 768 ? '50vh' : '70vh',
            }} preview={false} />
          </div>
          <div className={'flex flex-col justify-center items-center lg:w-1/3 w-full h-full p-2 md:p-4'}>
            {state.map((item, state) => (
              <motion.div key={item.id}
                whileHover={{scale: 1.05}}
                transition={{duration: 0.3}}
                className={'bg-yellow-400 w-44 p-2 m-4 text-center'}>
                {item.state}
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      <div className={'flex flex-col justify-center items-center lg:p-10 mb-10'}>
        <div ref={ref} style={{
          transform: isInView ? 'none' : 'translateX(-200px)',
          opacity: isInView ? 1 : 0,
          transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }} className={'p-8 md:p-2 text-xl md:text-4xl font-bold md:mb-10'}>
          <h1>Serving You Across Maharashtra</h1>
        </div>
        <div className={'flex flex-col lg:flex-row'}>
          <div className={'flex justify-center items-center'}>
            <Image src={maharashtra} style={{
              width: window.innerWidth < 768 ? '60vh' : '90vh',
              height: window.innerWidth < 768 ? '40vh' : '60vh',
            }} preview={false} />
          </div>
          <div className={'grid grid-cols-2 lg:w-1/3 w-full h-full p-2 md:p-4 justify-center items-center'}>
            {city.map((item, index) => (
              <motion.div
                key={item.id}
                whileHover={{scale: 1.05}}
                transition={{duration: 0.3}}
                className={'bg-yellow-400 md:w-20 lg:w-40 h-10 md:p-2 m-2 md:m-4 text-center flex justify-center items-center'}>
                {item.state}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <FooterScreen />
    </div>
  );
};

export default DealersScreen;
