import {Carousel, Image} from 'antd';

const data = [
  {
    id: 1,
    image: require('../../../assets/Images/cowHome.png'),
  },
  {
    id: 2,
    image: require('../../../assets/Images/cowHome.png'),
  },
  {
    id: 3,
    image: require('../../../assets/Images/cowHome.png'),
  },
  {
    id: 4,
    image: require('../../../assets/Images/cowHome.png'),
  },
];

const CarouselScreen = () => {
  return (
    <div className={'justify-center p-2 w-full'}>
      <Carousel arrows infinite={true} autoplay={true}>
        {data.map(id => (
          <div key={id} className={''}>
            <Image src={id.image} preview={false} className={'rounded-xl'} style={{
              width: window.innerWidth < 768 ? '60vh' : '250vh',
              height: window.innerWidth < 768 ? '30vh' : '60vh',
            }} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselScreen;
