import {Button, Form, Image, Input} from 'antd';

import contact from '../../../assets/Images/contact.png';

const onFinish = values => {
  // Form WhatsApp message with form data
  const whatsappMessage = `Full Name: ${values.name}\nPhone No.: ${values['phone number']}\nCity: ${values.city}\nEmail: ${values.email}\nMessage: ${values.message}`;

  // Encode message for URL
  const encodedMessage = encodeURIComponent(whatsappMessage);

  // Construct WhatsApp link with the encoded message
  const whatsappLink = `https://wa.me/9822276721?text=${encodedMessage}`;

  // Open WhatsApp link
  window.open(whatsappLink, '_blank');
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const ContactForm = () => {
  return(
    <div className={'flex flex-col w-full justify-center items-center p-4 lg:p-7 lg:w-3/5'}>
      <div>
        <h1 className={'text-2xl lg:text-4xl font-bold'}>Write a Message</h1>
      </div>
      <div className={'p-4 flex flex-col lg:flex-row lg:w-4/5'}>
        <div className={'w-2/3'}>
          <Image src={contact} preview={false} />
        </div>
        <Form className={'lg:w-4/5'}
          {...layout}
          name={'nest-messages'}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete={'off'}
          style={{maxWidth: 600}}
          initialValues={{remember: true}}
          validateMessages={validateMessages}>
          <div className={'flex flex-col'}>
            <Form.Item
              name={'name'} rules={[{required: true, message: 'Please input your full name!'}]}>
              <Input placeholder={'Name'} />
            </Form.Item>
            <Form.Item name={'email'} rules={[{required: true, message: 'Please input your email!'}]}>
              <Input placeholder={'Email'} />
            </Form.Item>
            <div>
              <Form.Item name={'message'} rules={[{required: true, message: 'Please input your message!'}]}>
                <Input.TextArea placeholder={'Message'} />
              </Form.Item>
            </div>
          </div>
          <Form.Item className={'text-center'}>
            <Button type={'primary'} className={'bg-green-800'} htmlType={'submit'}>Send a Email</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
