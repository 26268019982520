import {Breadcrumb} from 'antd';
import {motion} from 'framer-motion';
import {useEffect} from 'react';

import about1 from '../../../assets/Images/cow6.jpg';
import about from '../../../assets/Images/cowHome1.jpg';
import {FooterScreen} from '../../FooterScreen';

const data = [
  {
    id: 1,
    img: <iframe className={'w-80'} width={'478'} height={'400'}
      src={'https://www.youtube.com/embed/Z9PZERpHH6w'}
      title={'या सर्व समस्यांपासून दूर राहण्यासाठी Good Feel Cow Mats घेताय ना ? |🌐 www.goodfeelcowmats.com'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 2,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/wGTRrbBjMDw'}
      title={'गाभण काळामध्ये आपल्या गाईला हवा असतो आराम, आणि आराम मिळेल तेव्हाच जेव्हा असेल Good Feel Cow Mats 💪'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 3,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/avmuP0EhZQA'}
      title={'💪 Good Feel Cow Mats - प्रत्येक ऋतूमध्ये अनुकूल 😇 | Cow Mats| Good Feel Cow Mats'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 4,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/XJWqJzkGkA0'}
      title={'ब्रँड म्हणजे गुड फील काऊ मॅट्स !💪|#shetkariraja #brand #brandisbrand #cowmats #matdealer #qualitymat'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 5,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/MJd7LQoeGXE'}
      title={'🌧पावसाळ्यामध्ये गुड फील काऊ मॅट्सच का घ्यायचे ? 🐄🌧|#cowcare #goodfeelcowmats #matsforcow #trend'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 6,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/7FpPSiNfQEk'}
      title={'सर्व शेतकरी बंधू भगिनींना महाराष्ट्र कृषी दिनाच्या हार्दिक शुभेच्छा ! 🌾🐄|www.goodfeelcowmats.com'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 7,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/pYjSV1Ju43w'}
      title={'मॅट एक, फायदे अनेक 💯💪🏻|🌐www.goodfeelcowmats.com|📧 goodfeelcowmats@gmail.com#goodfeelcowmats #cowmats'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 8,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/2CqCGwwslvQ'}
      title={'मान्सून मे गायो की देखभाल कैसे करें ?|🌐 www.goodfeelcowmats.com|📧goodfeelcowmats@gmail.com|#goodfeel'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 9,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/MYogEgsuHUY'}
      title={'Good Feel Cow Mats का उपयोग करणे के मुख्य ३ कारण ! 🐄💯 |🌐www.goodfeelcowmats.com|#goodfeelcowmats#mat'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 10,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/6_eiFu-we1Q'}
      title={'Good Feel Cow Mats – हर डेयरी फार्म की बुनियादी आवश्यकता। 🐄💯|www.goodfeelcowmats.com#goodfeelcowmats'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
  {
    id: 11,
    img: <iframe className={'w-80'} width={'478'} height={'400'} src={'https://www.youtube.com/embed/dRh1xBUaN1g'}
      title={'गायो के लिये सबसे अच्छा और किसानो का भरोसेमंद Good Feel Cow Mats 💪🏻🐄|www.goodfeelcowmats.com|#cowmat'}
      frameBorder={'0'}
      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
      referrerPolicy={'strict-origin-when-cross-origin'} allowFullScreen></iframe>,
  },
];

const GalleryVideos = () =>{
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${about1})` : `url(${about})`,
        height: '60vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: '-1px 0%',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'flex flex-col justify-center items-center text-center pt-44'}>
          <Breadcrumb className={'opacity-90'}
            separator={<span className={'separator text-white'}>/</span>}
            items={[
              {
                title: (
                  <span className={'text-white'}>HOME</span>
                ),
              },
              {
                title: (
                  <span className={'text-white'}>VIDEOS</span>
                ),
              }]}
          />
          <h1 className={'text-4xl font-bold md:text-6xl text-white'}>Videos</h1>
        </div>
      </div>
      <div className={'flex flex-wrap justify-center items-center p-4 lg:p-10'}>
        {data.map((id, title, description, img) => (
          <motion.div key={id}  whileHover={{scale: 1.1}}
            whileTap={{scale: 0.9}}
            transition={{type: 'spring', stiffness: 400, damping: 10}} className={'py-8 lg:w-80 lg:m-4'}>
            {id.img}
          </motion.div>
        ))}
      </div>
      <FooterScreen />
    </div>
  );
};

export default GalleryVideos;
