import {Image} from 'antd';
import {FaFacebook, FaInstagramSquare, FaPhoneAlt, FaYoutube} from 'react-icons/fa';
import {IoLogoWhatsapp, IoMdMail} from 'react-icons/io';
import {IoLocation} from 'react-icons/io5';
import {Link} from 'react-router-dom';

import logo from '../../assets/Images/logo5.png';

import {CompanyFooter} from './CompanyInfoFooter';

const FooterScreen = () => {
  return (
    <>
      <div className={'flex flex-col lg:flex-row justify-around items-center lg:p-4 p-4 animated-background'}>
        <div className={'text-white w-80'}>
          <Image src={logo} preview={false}
            style={{
              width: window.innerWidth < 768 ? '25vh' : '20vh',
              height: window.innerWidth < 768 ? '10vh' : '10vh',
            }}
            className={'mt-1'} alt={'company logo'} />
          <div className={'flex'}>
            <h1>
              <Link to={'https://www.youtube.com/@GoodFeelCowMats'} target={'_blank'}>
                <FaYoutube  className={'mr-2 mt-2 bg-black rounded-full w-10 h-10 p-3'} />
              </Link>
            </h1>
            <h1>
              <Link to={'https://www.facebook.com/Goodfeelcowmat/'} target={'_blank'}>
                <FaFacebook className={'mr-2 mt-2 bg-black rounded-full w-10 h-10 p-3'} />
              </Link>
            </h1>
            <h1>
              <Link to={'https://wa.me/message/IJ36MRIPS5W7N1'} target={'_blank'}>
                <IoLogoWhatsapp className={'mr-2 mt-2 bg-black rounded-full w-10 h-10 p-3'} />
              </Link>
            </h1>
            <h1>
              <Link to={'https://www.instagram.com/goodfeelcowmats?igsh=MXV4NXJrdTh1M28zZA=='} target={'_blank'}>
                <FaInstagramSquare className={'mr-2 mt-2 bg-black rounded-full w-10 h-10 p-3'} />
              </Link>
            </h1>
          </div>
        </div>
        <div className={'text-black w-80'}>
          <h1 className={'py-2'}><span className={'border-green-600 border-b-2'}>Contact</span></h1>
          <div className={'flex flex-col text-sm'}>
            <h1 className={'flex'}><FaPhoneAlt className={'mr-2'} />7744001589 | 9822276721</h1>
            <h1 className={'flex'}><IoMdMail className={'mr-2'} />contact@goodfeelcowmats.com</h1>
            <h1 className={'flex'}><IoLocation className={'mr-2'} />Main road kolhapur-Sangli Highway, Herle, kolhapur-416109</h1>
          </div>
        </div>
      </div>
      <CompanyFooter />
    </>
  );
};

export default FooterScreen;
