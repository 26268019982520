import {Breadcrumb, Card} from 'antd';
import {useEffect} from 'react';
import {FaPhone} from 'react-icons/fa';
import {FaLocationDot} from 'react-icons/fa6';
import {IoMdMail} from 'react-icons/io';

import about1 from '../../assets/Images/cowMat3.jpg';
import about from '../../assets/Images/cowMat4.jpg';
import {FooterScreen} from '../FooterScreen';

import {ContactForm} from './ContactForm';

const data = [
  {
    id: 1,
    description: (<Card className={'bg-green-400 h-48 w-72'}>
      <h1 className={'text-xl p-2 text-center'}>About</h1>
      <div className={'flex'}>
        <h1 className={'m-2'}>Founded in 2020, Good Feel Cow Mats is dedicated to providing high-quality, durable, and comfortable mats for dairy farms.</h1>
      </div>
    </Card>),
  },
  {
    id: 2,
    description: (<Card className={'bg-lime-400 h-48 w-72'}>
      <h1 className={'text-xl p-2 text-center'}>Contact</h1>
      <div className={'flex p-2'}>
        <FaPhone className={'text-lg mr-2'} />
        <h1> 7744001589 | 9822276721</h1>
      </div>
      <div className={'flex p-2'}>
        <IoMdMail className={'text-2xl mr-2'} />
        <h1>contact@goodfeelcowmats.com</h1>
      </div>
    </Card>),
  },
  {
    id: 3,
    description: (<Card className={'bg-amber-400 h-48 w-72'}>
      <h1 className={'text-xl p-2 text-center'}>Address</h1>
      <div className={'flex'}>
        <FaLocationDot className={'text-xl'} />
        <h1 className={'ml-4'}>Main road kolhapur-Sangli Highway, Herle, kolhapur-416109</h1>
      </div>
    </Card>),
  },
];

const ContactUsScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${about})` : `url(${about1})`,
        height: '60vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: '-1px 0%',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'flex flex-col justify-center items-center text-center pt-44'}>
          <Breadcrumb className={'opacity-90'}
            separator={<span className={'separator text-white'}>/</span>}
            items={[
              {
                title: (
                  <span className={'text-white'}>HOME</span>
                )},
              {
                title: (
                  <span className={'text-white'}>CONTACT</span>
                )},
            ]}
          />
          <h1 className={'text-4xl font-bold md:text-6xl text-white'}>Contact</h1>
        </div>
      </div>
      <div className={'flex flex-col justify-center items-center p-10'}>
        <div className={'flex flex-col lg:flex-row gap-4 lg:gap-8 p-4 lg:p-10'}>
          {data.map((id, description) => (
            <div key={id}
              style={{
                width: 300,
              }}>
              <p>{id.description}</p>
            </div>
          ))}
        </div>
        <ContactForm />
        <div className={'py-8 lg:w-3/5'}>
          <iframe className={'w-full'}
            src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.6022049993635!2d74.3298379759014!3d16.746688720944725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc103ee4d63eb85%3A0x73f78c9e241dd2bf!2z8J2QhvCdkI7wnZCO8J2QgyDwnZCF8J2QhPCdkITwnZCLIPCdkILwnZCO8J2QliDwnZCM8J2QgPCdkJPwnZCS!5e0!3m2!1sen!2sin!4v1730800766894!5m2!1sen!2sin'}
            width={'900'}
            height={'450'}
            style={{border: 0}}
            allowFullScreen={''}
            loading={'lazy'}
            referrerPolicy={'no-referrer-when-downgrade'}
            title={'Location map for Good Feel Cow Mat'}>
          </iframe>
        </div>
      </div>
      <FooterScreen />
    </div>
  );
};

export default ContactUsScreen;
