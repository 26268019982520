import {CloseOutlined, MenuOutlined} from '@ant-design/icons';
import {Button, Drawer, Image, Layout} from 'antd';
import  {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import logo from '../../assets/Images/logo4.png';
import {HeaderScreen} from '../HeaderScreen';

import {LeftMenu} from './LeftMenu';
import {RightMenu} from './RightMenu';

const NavigationBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const location = useLocation();
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  const isWeb = window.screen.width > 640;

  const isLogoVisible = window.screen.width < 640;

  return (
    <div className={'sticky top-0 z-50 bg-white shadow border-gray-200'}>
      { isWeb &&  <HeaderScreen />}
      <Layout>
        <Layout.Header className={'flex items-center justify-around bg-white md:h-20 h-20 p-0'}>
          { isLogoVisible && (
            <div className={'md:w-0 w-36 md:m-0 m-0'}>
              <NavLink exact to={'/About'} className={'nav-logo'}>
                <Image src={logo} preview={false}
                  style={{
                    width: window.innerWidth < 768 ? '20vh' : '22vh',
                    height: window.innerWidth < 768 ? '8vh' : '80vh',
                  }}
                  className={'mt-8'} alt={'company logo'} />
              </NavLink>
            </div>
          )}
          <div>
            <div className={'hidden md:flex'}>
              <LeftMenu mode={'horizontal'} />
            </div>
            <Button
              className={'md:hidden text-black text-2xl'}
              type={'text'}
              onClick={toggleDrawer}>
              <MenuOutlined />
            </Button>
            <div className={'hidden md:flex'}>
              <RightMenu mode={'horizontal'} />
            </div>
            <div>
              <Drawer
                title={(
                  <div>
                    <Image src={logo} alt={'Brand Logo'} height={30}
                      style={{
                        width: window.innerWidth < 768 ? '20vh' : '22vh',
                        height: window.innerWidth < 768 ? '7vh' : '8vh',
                      }}
                    />
                  </div>
                )}
                placement={'right'}
                closable={true}
                closeIcon={<CloseOutlined style={{color: 'black'}} />}
                onClose={toggleDrawer}
                open={isDrawerOpen}
                style={{zIndex: 99999, backgroundColor: 'white'}}>
                <LeftMenu mode={'inline'} />
              </Drawer>
            </div>
          </div>
        </Layout.Header>
      </Layout>
    </div>
  );
};

export default NavigationBar;
