import './App.css';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';

import {AboutUsScreen} from './screenComponents/AboutUsScreen';
import {ContactUsScreen} from './screenComponents/ContactUsScreen';
import {DealersScreen} from './screenComponents/DealersScreen';
import {GalleryPhotos} from './screenComponents/GalleryScreen/GalleryPhotos';
import {GalleryVideos} from './screenComponents/GalleryScreen/GalleryVideos';
import {HomeScreen} from './screenComponents/HomeScreen';
import {NavigationBar} from './screenComponents/NavigationBar';
import {ProductDetails} from './screenComponents/ProductDetails';

const App = () => {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path={'/'} element={<HomeScreen />} />
        <Route path={'/About'} element={<AboutUsScreen />} />
        <Route path={'/Product'} element={<ProductDetails />} />
        <Route path={'/Dealers'} element={<DealersScreen />} />
        <Route path={'/Videos'} element={<GalleryVideos />} />
        <Route path={'/Photos'} element={<GalleryPhotos />} />
        <Route path={'/Contact'} element={<ContactUsScreen />} />
      </Routes>
    </Router>
  );
};

export default App;
