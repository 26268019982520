import {Breadcrumb, Image} from 'antd';
import {useEffect} from 'react';

import about1 from '../../../assets/Images/cow4.jpg';
import about from '../../../assets/Images/cowMat3.jpg';
import {FooterScreen} from '../../FooterScreen';

const data = [
  {
    id: 1,
    img: require('../../../assets/Images/mat1.jpg'),
  },
  {
    id: 2,
    img: require('../../../assets/Images/mat2.jpg'),
  },
  {
    id: 3,
    img: require('../../../assets/Images/mat3.jpg'),
  },
  {
    id: 4,
    img: require('../../../assets/Images/mat4.jpg'),
  },
  {
    id: 5,
    img: require('../../../assets/Images/mat5.jpg'),
  },
  {
    id: 6,
    img: require('../../../assets/Images/mat6.jpg'),
  },
  {
    id: 7,
    img: require('../../../assets/Images/mat7.jpg'),
  },
  {
    id: 8,
    img: require('../../../assets/Images/mat8.jpg'),
  },
  {
    id: 9,
    img: require('../../../assets/Images/mat9.jpg'),
  },
  {
    id: 10,
    img: require('../../../assets/Images/mat10.jpg'),
  },
  {
    id: 11,
    img: require('../../../assets/Images/mat11.jpg'),
  },
  {
    id: 12,
    img: require('../../../assets/Images/mat12.jpg'),
  },
  {
    id: 13,
    img: require('../../../assets/Images/mat13.jpg'),
  },
  {
    id: 14,
    img: require('../../../assets/Images/image5.jpeg'),
  },
  {
    id: 15,
    img: require('../../../assets/Images/image6.jpeg'),
  },
  {
    id: 16,
    img: require('../../../assets/Images/image7.jpeg'),
  },
  {
    id: 17,
    img: require('../../../assets/Images/image8.jpeg'),
  },
  {
    id: 18,
    img: require('../../../assets/Images/image9.jpeg'),
  },
];

const GalleryPhotos = () =>{
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${about1})` : `url(${about})`,
        height: '60vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: '-1px 0%',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'flex flex-col justify-center items-center text-center pt-44'}>
          <Breadcrumb className={'opacity-90'}
            separator={<span className={'separator text-white'}>/</span>}
            items={[
              {
                title: (
                  <span className={'text-white'}>HOME</span>
                ),
              },
              {
                title: (
                  <span className={'text-white'}>PHOTOS</span>
                ),
              }]}
          />
          <h1 className={'text-4xl font-bold md:text-6xl text-white'}>Photos</h1>
        </div>
      </div>
      <div className={'flex flex-wrap justify-center items-center p-4 lg:p-10 border-3 border-red-900'}>
        {data.map((id, title, description, img) => (
          <div key={id}>
            <div className={'lg:w-full h-full m-2 md:m-6'}>
              <Image src={id.img} style={{
                width: window.innerWidth < 768 ? '46vh' : '50vh',
                height: window.innerWidth < 768 ? '50vh' : '50vh',
              }} preview={true} />
            </div>
          </div>
        ))}
      </div>

      <FooterScreen />
    </div>
  );
};

export default GalleryPhotos;
