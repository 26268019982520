import {Breadcrumb, Image} from 'antd';
import {motion} from 'framer-motion';
import {data} from 'framer-motion/m';
import {useEffect, useState} from 'react';
import {IoLogoWhatsapp} from 'react-icons/io';
import {Link} from 'react-router-dom';

import about1 from '../../assets/Images/cowMat3.jpg';
import about from '../../assets/Images/cowMat4.jpg';
import product3 from '../../assets/Images/foam_mat.jpg';
import product1 from '../../assets/Images/inter_locking.png';
import product2 from '../../assets/Images/regular1.png';
import {FooterScreen} from '../FooterScreen';

const ProductDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex(state => {
        if (state >= data.length - 1) {return 0;}
        return state + 1;
      });
    }, 2000);
    return () => clearInterval(id);
  }, []);

  return (
    <div className={'min-h-screen bg-white'}>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${about})` : `url(${about1})`,
        height: '60vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: '-1px 0%',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'flex flex-col justify-center items-center text-center pt-44'}>
          <Breadcrumb className={'opacity-90'}
            separator={<span className={'separator text-white'}>/</span>}
            items={[
              {
                title: (
                  <span className={'text-white'}>HOME</span>
                ),
              },
              {
                title: (
                  <span className={'text-white'}>PRODUCT</span>
                ),
              },
            ]}
          />
          <h1 className={'text-4xl font-bold md:text-6xl text-white'}>Product</h1>
        </div>
      </div>

      {/*1st Product*/}
      <div className={'flex flex-col lg:flex-row justify-center items-center p-4 md:p-10'}>
        <div className={'flex justify-center items-center lg:w-1/3 h-full'}>
          <Image src={product2} style={{
            width: window.innerWidth < 768 ? '46vh' : '80vh',
            height: window.innerWidth < 768 ? '40vh' : '60vh',
          }} className={'rounded-xl'} preview={false} />
        </div>
        <div className={'lg:w-1/3 h-full p-12'}>
          <motion.h1 initial={{
            opacity: 0,
            x: index % 2 === 5 ? 30 : -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 3,
            },
          }}
          className={'font-bold text-xl lg:text-3xl'}>
            <span className={'py-4 border-gray-600 border-opacity-20 border-b-2'}>
                  40 Kg Regular Mat
            </span>
          </motion.h1>
          <p className={'mt-8 text-sm md:text-xl'}>
            Our 40 kg regular mat offers superior comfort and durability, perfect for enhancing the well-being of
            your livestock
          </p>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Weight :</span> 40 kg</h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Thickness :</span> 17-18 mm </h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Size :</span>6.5 x 4 ft</h3>
          <div className={'py-8'}>
            <button className={'flex w-40 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'}>
              <h1>Contact Us</h1>
              <Link to={'https://wa.me/message/IJ36MRIPS5W7N1'} target={'_blank'}>
                <IoLogoWhatsapp className={'text-2xl ml-4'} />
              </Link>
            </button>
          </div>
        </div>
      </div>

      {/*2nd Product*/}
      <div className={'flex flex-col lg:flex-row-reverse justify-center items-center p-2'}>
        <div className={'flex justify-center items-center lg:w-1/3 h-full'}>
          <Image src={product1} style={{
            width: window.innerWidth < 768 ? '46vh' : '80vh',
            height: window.innerWidth < 768 ? '40vh' : '50vh',
          }} className={'rounded-xl'} preview={false} />
        </div>
        <div className={'lg:w-1/3 h-full p-12'}>
          <motion.h1  initial={{
            opacity: 0,
            x: index % 2 === 5 ? 30 : -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 3,
            },
          }}
          className={'font-bold text-xl lg:text-3xl'}>
            <span className={'py-4 border-gray-600 border-opacity-20 border-b-2'}>
                  40 Kg Interlocking Mat
            </span>
          </motion.h1>
          <p className={'mt-8 text-sm md:text-xl'}>
            Designed for easy installation, the 40 kg interlocking mat ensures a snug fit and provides excellent
            comfort, making it ideal for high-traffic areas in your dairy farm.
          </p>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Weight :</span> 40 kg</h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Thickness :</span> 17-18 mm </h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Size :</span> 6.5 x 4 ft</h3>
          <div className={'py-8'}>
            <button className={'flex w-40 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'}>
              <h1>Contact Us</h1>
              <Link to={'https://wa.me/message/IJ36MRIPS5W7N1'} target={'_blank'}>
                <IoLogoWhatsapp className={'text-2xl ml-4'} />
              </Link>
            </button>
          </div>
        </div>
      </div>

      {/*3rd Product*/}
      <div className={'flex flex-col lg:flex-row justify-center items-center p-2'}>
        <div className={'flex justify-center items-center lg:w-1/3 h-full'}>
          <Image src={product2} style={{
            width: window.innerWidth < 768 ? '46vh' : '80vh',
            height: window.innerWidth < 768 ? '40vh' : '60vh',
          }} className={'rounded-xl'} preview={false} />
        </div>
        <div className={'lg:w-1/3 h-full p-12'}>
          <motion.h1 initial={{
            opacity: 0,
            x: index % 2 === 5 ? 30 : -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 3,
            },
          }}
          className={'font-bold text-xl lg:text-3xl'}>
            <span className={'py-4 border-gray-600 border-opacity-20 border-b-2'}>
                32 Kg Regular Mat
            </span>
          </motion.h1>
          <p className={'mt-8 text-sm md:text-xl'}>
            Lightweight yet durable, the 32 kg regular mat delivers consistent performance with added flexibility
            for easier handling and installation.
          </p>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Weight :</span> 32 kg</h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Thickness :</span> 15-16 mm </h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Size :</span> 6.5 x 4 ft</h3>
          <div className={'py-8'}>
            <button className={'flex w-40 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'}>
              <h1>Contact Us</h1>
              <Link to={'https://wa.me/message/IJ36MRIPS5W7N1'} target={'_blank'}>
                <IoLogoWhatsapp className={'text-2xl ml-4'} />
              </Link>
            </button>
          </div>
        </div>
      </div>

      {/*4th Product*/}
      <div className={'flex flex-col lg:flex-row-reverse justify-center items-center p-2'}>
        <div className={'flex justify-center items-center lg:w-1/3 h-full'}>
          <Image src={product1} style={{
            width: window.innerWidth < 768 ? '46vh' : '70vh',
            height: window.innerWidth < 768 ? '40vh' : '45vh',
          }} className={'rounded-xl'} preview={false} />
        </div>
        <div className={'lg:w-1/3 h-full p-12'}>
          <motion.h1 initial={{
            opacity: 0,
            x: index % 2 === 5 ? 30 : -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 3,
            },
          }}
          className={'font-bold text-xl lg:text-3xl'}>
            <span className={'py-4 border-gray-600 border-opacity-20 border-b-2'}>
                  32 Kg Interlocking Mat
            </span>
          </motion.h1>
          <p className={'mt-8 text-sm md:text-xl'}>
            The 32 kg interlocking mat combines convenience with comfort, offering a secure, interlocked setup that
            minimizes movement and increases cow comfort.
          </p>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Weight :</span>32 kg</h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Thickness :</span>15-16 mm </h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Size :</span>6.5 x 4 ft</h3>
          <div className={'py-8'}>
            <button className={'flex w-40 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'}>
              <h1>Contact Us</h1>
              <Link to={'https://wa.me/message/IJ36MRIPS5W7N1'} target={'_blank'}>
                <IoLogoWhatsapp className={'text-2xl ml-4'} />
              </Link>
            </button>
          </div>
        </div>
      </div>

      {/*5th Product*/}
      <div className={'flex flex-col lg:flex-row justify-center items-center p-2 mb-10'}>
        <div className={'flex justify-center items-center lg:w-1/3 h-full'}>
          <Image src={product3} style={{
            width: window.innerWidth < 768 ? '46vh' : '70vh',
            height: window.innerWidth < 768 ? '40vh' : '45vh',
          }} className={'rounded-xl'} preview={false} />
        </div>
        <div className={'lg:w-1/3 h-full p-12'}>
          <motion.h1 initial={{
            opacity: 0,
            x: index % 2 === 5 ? 30 : -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 3,
            },
          }}
          className={'font-bold text-xl lg:text-3xl'}>
            <span className={'py-4 border-gray-600 border-opacity-20 border-b-2'}>
                Foam Mat
            </span>
          </motion.h1>
          <p className={'mt-8 text-sm md:text-xl py-2'}>
            Our foam mat is lightweight and ultra-comfortable, providing soft cushioning for cows, ideal for
            sensitive animals or resting areas where extra support is needed.
          </p>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Weight :</span>14-15 kg</h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Thickness :</span> 27-30 mm</h3>
          <h3 className={'text-sm md:text-lg'}><span className={'font-bold'}>Size : </span>5 x 8 ft</h3>
          <div className={'py-8'}>
            <button className={'flex w-40 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600'}>
              <h1>Contact Us</h1>
              <Link to={'https://wa.me/message/IJ36MRIPS5W7N1'} target={'_blank'}>
                <IoLogoWhatsapp className={'text-2xl ml-4'} />
              </Link>
            </button>
          </div>
        </div>
      </div>
      <FooterScreen />
    </div>
  );
};

export default ProductDetails;
