import {Menu} from 'antd';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Link} from 'react-router-dom';

const items = [
  {
    label: (
      <Link to={'/'}>
        <h1 className={'text-xl font-semibold text-black'}>Home</h1>
      </Link>
    ),
    key: 'Home',
  },
  {
    label:(
      <Link to={'/About'}>
        <h1 className={'text-xl font-semibold text-black'}>About</h1>
      </Link>
    ),
    key: 'About',
  },
  {
    label:(
      <Link to={'/Product'}>
        <h1 className={'text-xl font-semibold text-black'}>Products</h1>
      </Link>
    ),
    key: 'Products',
  },
  {
    label:(
      <Link to={'/Dealers'}>
        <h1 className={'text-xl font-semibold text-black'}>Dealers</h1>
      </Link>
    ),
    key: 'Dealers',
  },
  {
    label:(
      <h1 className={'text-xl font-semibold text-black'}>Gallery</h1>
    ),
    key: 'SubMenu',
    children: [
      {
        label:(
          <Link to={'/Videos'}>
            <h1 className={'text-xl font-semibold text-black'}>Gallery Videos</h1>
          </Link>
        ),
        key: 'Gallery:1',
      },
      {
        label:(
          <Link to={'/Photos'}>
            <h1 className={'text-xl font-semibold text-black'}>Gallery Photos</h1>
          </Link>
        ),
        key: 'Gallery:1',
      },
    ],
  },
  {
    label:(
      <Link to={'/Contact'}>
        <h1 className={'text-xl font-semibold text-black'}>Contact</h1>
      </Link>
    ),
    key: 'Contact',
  },
];

const LeftMenu = ({mode}) => {
  const [current, setCurrent] = useState('mail');
  const onClick = e => {
    console.log('click ', e);
    setCurrent(e.key);
  };
  return (
    <nav className={'md:w-[42rem]'}>
      <Menu items={items} onClick={onClick} selectedKeys={[current]} mode={mode}
        className={'bg-white md:flex items-center justify-center'} />
    </nav>
  );
};

LeftMenu.propTypes = {
  mode: PropTypes.oneOf(['horizontal', 'vertical', 'inline']).isRequired,
};

export default LeftMenu;
