import {useRef} from 'react';

import bgvideo from '../../../assets/Images/videobg.mp4';

const VideoScreen = () => {
  const videoRef = useRef(null);

  return (
    <div className={'relative'}>
      <video
        style={{
          height: window.innerWidth < 768 ? '40vh' : '50vh',
        }}
        ref={videoRef}
        autoPlay
        loop
        muted
        id={'myVideo'}
        className={'w-full h-96 object-fill'}>
        <source src={bgvideo} type={'video/mp4'} />
                Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoScreen;
