import {Breadcrumb, Image} from 'antd';
import {motion, useInView} from 'framer-motion';
import {useEffect, useRef} from 'react';

import CEO from '../../assets/Images/CEO.jpg';
import about from '../../assets/Images/cowHome1.jpg';
import cowMat1 from '../../assets/Images/cowMat1.jpg';
import {FooterScreen} from '../FooterScreen';

import {CompanyVision} from './CompanyVision';
import Distributors from './Distributors/Distributors';
import {ReviewScreen} from './ReviewScreen';
import {VideoScreen} from './VideoScreen';

const AboutUsScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  return(
    <div>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${cowMat1})` : `url(${about})`,
        height: '60vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: '-1px 0%',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'flex flex-col justify-center items-center text-center pt-44'}>
          <Breadcrumb className={'opacity-90'}
            separator={<span className={'separator text-white'}>/</span>}
            items={[
              {
                title: (
                  <span className={'text-white'}>HOME</span>
                )},
              {
                title: (
                  <span className={'text-white'}>ABOUT</span>
                )} ]} />
          <h1 className={'text-4xl font-bold md:text-6xl text-white'}>About</h1>
        </div>
      </div>
      <div className={'flex flex-col justify-center items-center lg:flex-row p-4 lg:p-10'}>
        <div className={'flex justify-center items-center'}>
          <Image src={CEO} style={{
            width: window.innerWidth < 768 ? '46vh' : '50vh',
            height: window.innerWidth < 768 ? '40vh' : '45vh',
          }} preview={false} className={'rounded-xl'} />
        </div>
        <div className={'md:w-2/4 w-full h-full p-2 md:p-4 text-center md:text-left md:ml-4'}>
          <div ref={ref} style={{
            transform: isInView ? 'none' : 'translateX(-200px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s'}}>
            <h1 className={'text-4xl font-bold'}>About the CEO</h1>
          </div>
          <h1 style={{
            transform: isInView ? 'none' : 'translateX(-200px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }} className={'text-green-600 text-3xl py-4 font-bold'}>
              Sudhakar Shripati Kumbhar
          </h1>
          <motion.div
            initial={{opacity: 0, x: 50}}
            animate={{opacity: 1, x: 0}}
            transition={{duration: 1}}
            className={'text-sm md:text-lg md:indent-10'}>
            Sudhakar Kumbhar, the CEO of Good Feel Cow Mats, has been at the forefront of revolutionizing the
            rubber mat industry for livestock. With a strong background in business and a keen understanding
            of farmers needs, He founded Good Feel Cow Mats in 2020 with a mission to offer superior-quality
            products that make a real difference in the agricultural sector. His leadership is marked by a
            commitment to excellence, using only the finest pure Kerala rubber to create mats that are not only
            durable but also designed to improve livestock health and comfort. He believes in building
            long-lasting relationships with customers and delivering unmatched service, which has helped the
            brand earn trust and loyalty across the farming community.
          </motion.div>
        </div>
      </div>
      <Distributors />

      <VideoScreen />

      <CompanyVision />

      <FooterScreen />
    </div>
  );
};

export default AboutUsScreen;
