import {Image} from 'antd';
import {useInView} from 'framer-motion';
import {useRef} from 'react';
import {FaCheckCircle} from 'react-icons/fa';

import home from '../../../assets/Images/cow1.png';

const CompanyInfo = () => {
  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  return(
    <div className={'flex flex-col lg:flex-row justify-center lg:p-10 p-4'}>
      <div className={'m-6'}>
        <Image src={home} style={{
          width: window.innerWidth < 768 ? '46vh' : '50vh',
          height: window.innerWidth < 768 ? '40vh' : '50vh',
        }} preview={false} className={'rounded-xl'} />
      </div>
      <div className={'md:w-2/4 h-auto m-0 md:m-6'}>
        <div ref={ref} style={{
          transform: isInView ? 'none' : 'translateX(-200px)',
          opacity: isInView ? 1 : 0,
          transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }}>
          <h1 className={'text-3xl font-bold text-center md:text-left'}>Good Feel Cow Mats: Enhancing Cow Comfort</h1>
          <h1 className={'text-green-500 font-bold py-4'}>About Us</h1>
        </div>
        <p className={'text-sm md:text-lg md:indent-10'}>
            Founded in 2020, Good Feel Cow Mats is dedicated to providing high-quality, durable, and comfortable mats
            for dairy farms. Our mats are designed to improve the well-being of cows by offering superior comfort,
            enhancing hygiene, and supporting increased milk production. At Good Feel Cow Mats, we are committed to
            helping farmers achieve better productivity while ensuring the health and comfort of their livestock.
        </p>

        <div className={'flex mt-4'}>
          <div className={'p-2'}>
            <div className={'flex'}>
              <FaCheckCircle className={'text-teal-500 mr-2'} />
              <h1>Durable Material</h1>
            </div>
            <div className={'flex'}>
              <FaCheckCircle className={'text-teal-500 mr-2'} />
              <h1>Comfortable for Cows</h1>
            </div>
            <div className={'flex'}>
              <FaCheckCircle className={'text-teal-500 mr-2'} />
              <h1>Improves Hygiene</h1>
            </div>
            <div className={'flex'}>
              <FaCheckCircle className={'text-teal-500 mr-2'} />
              <h1>Eco-Friendly</h1>
            </div>
            <div className={'flex'}>
              <FaCheckCircle className={'text-teal-500 mr-2'} />
              <h1>Enhances Productivity</h1>
            </div>
            <div className={'flex'}>
              <FaCheckCircle className={'text-teal-500 mr-2'} />
              <h1>Non-Slip Surface</h1>
            </div>
            <div className={'flex'}>
              <FaCheckCircle className={'text-teal-500 mr-2'} />
              <h1>Stable Foundation</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
