
const CompanyFooter = () => {
  return (
    <div className={'flex flex-col lg:flex-row justify-around items-center lg:p-4 p-4 bg-zinc-900 text-white text-sm'}>
      <h1>Good Feel Cow Mat | All Rights Reserved.</h1>
      <h1>Developed by: Quantum Infotech</h1>
    </div>
  );
};

export default CompanyFooter;
