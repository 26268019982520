import {Image} from 'antd';
import {motion, useInView} from 'framer-motion';
import {useEffect, useRef, useState} from 'react';
import {FaCheckCircle} from 'react-icons/fa';

import about2 from '../../assets/Images/about2.jpg';
import mat1 from '../../assets/Images/cow2.jpeg';
import about from '../../assets/Images/cowHome.png';
import {FooterScreen} from '../FooterScreen';
import {ServicesScreen} from '../ServicesScreen' ;

import {CarouselScreen} from './CarouselScreen';
import {CompanyInfo} from './CompanyInfo';
import {VideoScreen} from './VideoScreen';

const data = [
  {
    id:1,
    title: 'Durable',
    description: 'We re using a new technology',
    img: require('../../assets/Images/image1.jpeg'),
  },
  {
    id:2,
    title: 'Warranty',
    description: 'Good in smart organic services',
    img: require('../../assets/Images/image4.jpeg'),
  },
  {
    id:3,
    title: 'Natural',
    description: 'Reforming ' +
        'in the systems',
    img: require('../../assets/Images/image3.jpeg'),
  },
];

const HomeScreen = () => {
  const [selected, setSelected] = useState(0);

  const toggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  return (
    <div>
      <CarouselScreen />
      <div className={'flex flex-row w-full justify-center items-center p-4 lg:p-10'}>
        {data.map((id, title, description, img) => (
          <motion.div
            whileHover={{scale: 1.02}}
            onClick={() => toggle(id)}
            transition={{duration: 0.3}} key={id} className={'flex flex-col m-2 justify-center items-center'}>
            <Image src={id.img} style={{
              width: window.innerWidth < 768 ? '20vh' : '22vh',
              height: window.innerWidth < 768 ? '16vh' : '18vh',
            }} className={'rounded-xl'} preview={false} />
          </motion.div>
        ))}
      </div>

      <CompanyInfo />

      <ServicesScreen />

      <VideoScreen />

      <div className={'flex flex-col lg:flex-row justify-center lg:p-10 p-4'}>
        <div className={'m-6'}>
          <Image src={mat1} style={{
            width: window.innerWidth < 768 ? '46vh' : '60vh',
            height: window.innerWidth < 768 ? '40vh' : '60vh',
          }} preview={false} className={'rounded-xl'} />
        </div>
        <div className={'md:w-2/4 h-full xl:h-auto m-0 md:m-6'}>
          <div ref={ref} style={{
            transform: isInView ? 'none' : 'translateX(-200px)',
            opacity: isInView ? 1 : 0,
            transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}>
            <h1 className={'text-3xl font-bold text-center md:text-left'}>Why Choose Us?</h1>
          </div>
          <p className={'text-sm md:text-lg m-2 md:indent-10'}>
          At Good Feel Cow Mats, we prioritize both the well-being of your cows and the success of your farm. Here&apos;s why you should choose us:
          </p>
          <div>
            <div className={'flex flex-col'}>
              <div className={'flex'}>
                <FaCheckCircle className={'mr-2 text-yellow-400 text-3xl'} />
                <motion.h1 initial={{opacity: 0, scale: 0.5}}
                  animate={{opacity: 1, scale: 1}}
                  transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  className={'font-bold text-xl'}>Premium Quality:</motion.h1>
              </div>
              <div className={'ml-10'}>
                <p className={'text-sm md:text-lg'}>
                  Our mats are made from durable, high-quality materials designed to withstand the toughest conditions
                  while providing maximum comfort for your livestock.
                </p>
              </div>
            </div>
            <div className={'flex flex-col'}>
              <div className={'flex'}>
                <FaCheckCircle className={'mr-2 text-teal-400 text-3xl'} />
                <h1 className={'font-bold text-xl'}>Health Benefits:</h1>
              </div>
              <div className={'ml-10'}>
                <p className={'text-sm md:text-lg'}>
                    Our mats reduce the risk of joint injuries, infections, and fatigue, promoting better health and well-being for your cows.
                </p>
              </div>
            </div>
            <div className={'flex flex-col'}>
              <div className={'flex'}>
                <FaCheckCircle className={'mr-2 text-yellow-400 text-3xl'} />
                <h1 className={'font-bold text-xl'}>Enhanced Productivity:</h1>
              </div>
              <div className={'ml-10'}>
                <p className={'text-sm md:text-lg'}>
                    There are variation You need to be sure there is anything hidden in the middle of text.
                </p>
              </div>
            </div>

            <div className={'flex flex-col'}>
              <div className={'flex'}>
                <FaCheckCircle className={'mr-2 text-teal-400 text-3xl'} />
                <h1 className={'font-bold text-xl'}>Cost-Effective:</h1>
              </div>
              <div className={'ml-10'}>
                <p className={'text-sm md:text-lg'}>
                With long-lasting durability and minimal maintenance, our mats are a smart investment that saves on frequent replacements and veterinary costs.
                </p>
              </div>
            </div>

            <div className={'flex flex-col'}>
              <div className={'flex'}>
                <FaCheckCircle className={'mr-2 text-yellow-400 text-3xl'} />
                <h1 className={'font-bold text-xl'}>Trusted Since 2020:</h1>
              </div>
              <div className={'ml-10'}>
                <p className={'text-sm md:text-lg'}>
                    With years of experience, we have built a reputation for reliability and innovation, making us the preferred choice for farmers across the region.
                </p>
              </div>
            </div>
          </div>
          <p className={'text-sm md:text-xl p-2'}>
             Choose Good Feel Cow Mats for a better, healthier, and more productive farm!
          </p>
        </div>
      </div>
      <FooterScreen />
    </div>
  );
};

export default HomeScreen;
