import {Card} from 'antd';
import {motion, useInView} from 'framer-motion';
import {data} from 'framer-motion/m';
import {useEffect, useRef, useState} from 'react';

import bg from '../../../assets/Images/background.png';

const CompanyVision = () => {
  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex(state => {
        if (state >= data.length - 1) {return 0;}
        return state + 1;
      });
    }, 2000);
    return () => clearInterval(id);
  }, []);

  return(
    <div style={{
      // backgroundImage: window.innerWidth < 768 ? `url(${bg})` : `url(${bg})`,
      backgroundSize: 'cover',
      backgroundBlendMode: 'hard-light',
      backgroundPosition: '-1px 0%',
      backgroundRepeat: 'no-repeat',
    }} className={'flex flex-col lg:flex-col gap-4 justify-center items-center p-4 animated-background'}>
      <div className={'lg:w-1/3 h-full text-center'} ref={ref} style={{
        transform: isInView ? 'none' : 'translateX(-200px)',
        opacity: isInView ? 1 : 0,
        transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
      }}>
        <h1 className={'text-3xl font-bold'}>Company Goal</h1>
      </div>
      <div className={'flex flex-col lg:flex-row'}>
        <Card className={'w-80 shadow-xl hover:shadow-2xl m-2'}>
          <motion.h1 initial={{
            opacity: 0,
            y: -10,
          }}
          whileInView={{
            opacity: 1,
            x: 0.9,
            transition: {
              duration: 3,
            },
          }}
          className={'text-2xl p-2 text-center font-bold'}><span className={'border-green-600 border-b-2'}>Vision</span></motion.h1>
          <p className={'text-center'}>Our vision is to become the No. 1 brand for cow mats, recognized for quality and innovation. We strive to
              be the first choice of farmers, providing products that enhance livestock comfort, health, and
              productivity.</p>
        </Card>
        <Card className={'w-80 shadow-xl hover:shadow-2xl m-2'}>
          <motion.h1 initial={{
            opacity: 0,
            y: -10,
          }}
          whileInView={{
            opacity: 1,
            x: 0.9,
            transition: {
              duration: 3,
            },
          }}
          className={'text-2xl p-2 text-center font-bold'}><span className={'border-green-600 border-b-2'}>Mission</span></motion.h1>
          <p className={'text-center'}>Our mission is to deliver high-quality, durable cow mats that prioritize the well-being of livestock
              while supporting farmers with cost-effective and sustainable solutions, driving increased productivity and
              long-term success.
          </p>
        </Card>
      </div>
    </div>
  );
};

export default CompanyVision;
