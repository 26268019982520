import {Divider, Image} from 'antd';
import {FaFacebook, FaInstagramSquare, FaYoutube} from 'react-icons/fa';
import {IoLogoWhatsapp} from 'react-icons/io';
import {Link, NavLink} from 'react-router-dom';

import logo from '../../assets/Images/logo4.png';

const HeaderScreen = () => {
  return (
    <div className={'sticky top-0 z-50 shadow border-gray-200 flex flex-col lg:flex-row justify-around items-center lg:p-2 p-4'}>
      <div className={'flex items-center justify-between bg-white h-16 p-0'}>
        <div className={'md:w-44 w-10 md:m-10 m-6'}>
          <NavLink exact to={'/About'} className={'nav-logo'}>
            <Image src={logo} preview={false}
              style={{
                width: window.innerWidth < 768 ? '20vh' : '22vh',
                height: window.innerWidth < 768 ? '10vh' : '8vh',
              }}
              className={'mt-1'} alt={'company logo'} />
          </NavLink>
        </div>
        <div className={'flex flex-col lg:flex-row p-4 xl:mx-20'}>
          <div className={'flex xl:mx-20'}>
            <h1>
              <Link to={'https://www.youtube.com/@GoodFeelCowMats'} target={'_blank'}>
                <FaYoutube className={'mr-2 mt-2 bg-gray-100 rounded-full lg:w-10 lg:h-10 p-3'} />
              </Link>
            </h1>
            <h1>
              <Link to={'https://www.facebook.com/Goodfeelcowmat/'} target={'_blank'}>
                <FaFacebook className={'mr-2 mt-2 bg-gray-100 rounded-full w-10 h-10 p-3'} />
              </Link>
            </h1>
            <h1>
              <Link to={'https://wa.me/message/IJ36MRIPS5W7N1'} target={'_blank'}>
                <IoLogoWhatsapp className={'mr-2 mt-2 bg-gray-100 rounded-full w-10 h-10 p-3'} />
              </Link>
            </h1>
            <h1>
              <Link to={'https://www.instagram.com/goodfeelcowmats?igsh=MXV4NXJrdTh1M28zZA=='} target={'_blank'}>
                <FaInstagramSquare className={'mr-2 mt-2 bg-gray-100 rounded-full w-10 h-10 p-3'} />
              </Link>
            </h1>
          </div>
          <div className={'flex'}>
            <div className={'flex items-center'}>
              <h2 className={'font-bold'}>7744001589 | 9822276721</h2>
            </div>
            <Divider variant={'solid'} type={'vertical'}
              style={{
                borderColor: '#7cb305',
                height: 50,
              }} />
            <div className={'flex items-center'}>
              <h2 className={'font-bold'}>contact@goodfeelcowmats.com</h2>
            </div>
            <Divider variant={'solid'} type={'vertical'}
              style={{
                borderColor: '#7cb305',
                height: 50,
              }} />
            <div className={'flex items-center'}>
              <h2 className={'font-bold'}> Herle, kolhapur-416109</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderScreen;
