import {useInView} from 'framer-motion';
import {useRef} from 'react';

const Distributors = () => {
  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  return(
    <div className={'flex flex-col justify-center items-center'}>
      <div className={'h-full p-4 text-center'} ref={ref} style={{
        transform: isInView ? 'none' : 'translateX(-200px)',
        opacity: isInView ? 1 : 0,
        transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
      }}>
        <h1 className={'text-green-600 pt-2 font-bold text-3xl'}>
          Dealer and Farmer Partnerships
        </h1>
      </div>
      <div className={'md:w-2/4 w-full h-full p-2 md:p-4 text-center md:text-left'}>
        <div className={'h-full p-2'}>
          <div className={'text-sm md:text-lg md:indent-10'}>
            At Good Feel Cow Mats, we pride ourselves on strong partnerships with both dealers and farmers. Our mats are trusted by dealers for their exceptional quality, easy maintenance, and long-lasting durability, making them a preferred choice in the market. We work closely with dealers to ensure timely delivery and excellent post-sales support, reinforcing our commitment to both quality and service. Farmers, on the other hand, appreciate the practical benefits of our mats in improving animal well-being, making their farms more efficient and productive.
          </div>
          <div className={'py-4'}>
            <h1 className={'flex text-sm md:text-lg md:indent-10'}>
              Through our growing network of trusted dealers, we continue to expand, delivering our top-tier products and services to farms across the region, all while maintaining the highest standards in quality, reliability, and customer satisfaction.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Distributors;
