import {Card, Image} from 'antd';
import {motion, useInView} from 'framer-motion';
import {useRef, useState} from 'react';

const data = [
  {
    id:1,
    img: require('../../assets/Images/new_product.png'),
    title: 'Cow Rubber Mats',
  },
  {
    id:2,
    img: require('../../assets/Images/new_product.png'),
    title: 'Electrical Rubber Mat',
  },
  {
    id:3,
    img: require('../../assets/Images/new_product.png'),
    title: 'Gym Flooring Rubber Mats',
  },
  {
    id:4,
    img: require('../../assets/Images/foam_mat.jpg'),
    title: 'Foam Mat',
  },
];

const ServicesScreen = () => {
  const [selected, setSelected] = useState(0);

  const toggle = index => {
    if (selected === index) {
      return setSelected(null); // Close if it's the same section
    }
    setSelected(index); // Otherwise, open the clicked section
  };

  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  return(
    <div className={'flex flex-col w-full justify-center items-center p-4 lg:p-10'}>
      <div className={'p-4'} ref={ref} style={{
        transform: isInView ? 'none' : 'translateX(-200px)',
        opacity: isInView ? 1 : 0,
        transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
      }}>
        <h1 className={'text-3xl font-bold'}>What we offer</h1>
      </div>
      <div className={'flex flex-col md:flex-row w-full justify-center items-center p-4 lg:p-10'}>
        {data.map((id, title, description, img) => (
          <motion.div
            whileHover={{scale: 1.02}}
            onClick={() => toggle(id)}
            transition={{duration: 0.3}} key={id} className={'flex flex-col m-3 justify-center items-center w-72 md:w-56'}>
            <Card className={''}>
              <Image src={id.img} style={{
                width: window.innerWidth < 768 ? '30vh' : '22vh',
                height: window.innerWidth < 768 ? '20vh' : '18vh',
              }} preview={false} />
              <Card
                className={'m-2 md:w-44 md:h-16 h-16 flex justify-center items-center text-center shadow-2xl bg-lime-100 hover:shadow-yellow-100'}>
                <motion.h1 initial={{opacity: 0, y: 50}}
                  animate={{opacity: isInView ? 1 : 0, y: isInView ? 0 : 50}}
                  transition={{duration: 0.5, delay: 0.5}}
                  className={'font-bold text-lg md:text-sm'}>{id.title}</motion.h1>
              </Card>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ServicesScreen;
